import { Link } from "gatsby";
import { Wrap } from "raam";
import React from "react";
import { Flex } from "theme-ui";
import logo from "../img/logo.svg";
import linkedInLogo from "../img/social/LinkedIn.svg";
import twitterLogo from "../img/social/Twitter.svg";
import youTubeLogo from "../img/social/YouTube.svg";
import useSiteMetadata from "./SiteMetadata";

const SocialLink = ({ url, logo, title }) => (
  <a href={url} target="_blank" rel="noopener" title={title}>
    <img aria-hidden="true" src={logo} />
  </a>
);

const Twitter = ({ username }) => (
  <SocialLink
    url={`https://twitter.com/${username}`}
    logo={twitterLogo}
    title="Follow us on Twitter"
  />
);

const LinkedIn = ({ username }) => (
  <SocialLink
    url={`https://www.linkedin.com/company/${username}`}
    logo={linkedInLogo}
    title="Follow us on LinkedIn"
  />
);

const YouTube = ({ username }) => (
  <SocialLink
    url={`https://www.youtube.com/channel/${username}`}
    logo={youTubeLogo}
    title="Follow us on YouTube"
  />
);

const Footer = () => {
  const { social } = useSiteMetadata();
  const { twitter, linkedIn, youTube } = social;

  return (
    <div className="pad footer">
      <div className="footerflex w-container">
        <Link to="/">
          <img alt="Compunetics" src={logo} width="250" />
        </Link>
      </div>
      <Flex sx={{ justifyContent: "center" }} mt={4}>
        <Wrap gap={"32px"}>
          <Twitter {...twitter} />
          <LinkedIn {...linkedIn} />
          <YouTube {...youTube} />
        </Wrap>
      </Flex>
    </div>
  );
};

export default Footer;
