import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";
import { useStaticQuery, graphql } from "gatsby";

const SEO = ({ frontmatter, isBlogPost, title: pageTitle, shortTitle }) => {
  const { pathname } = useLocation();
  const { site } = useStaticQuery(query);

  const { author, description, featuredImage, date, title: postTitle } =
    frontmatter || {};
  const postImage = featuredImage && featuredImage.childImageSharp.fixed.src;

  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    defaultImage,
    twitterUsername,
  } = site.siteMetadata;

  const seo = {
    title: shortTitle ? defaultTitle : pageTitle || postTitle || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${postImage || defaultImage}`,
    url: `${siteUrl}${pathname}`,
    siteName: defaultTitle,
    date,
    author,
  };

  return (
    <Helmet
      title={seo.title}
      titleTemplate={shortTitle ? undefined : titleTemplate}
    >
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      {/* Open Graph */}
      {seo.url && <meta property="og:url" content={seo.url} />}
      {seo.siteName && <meta property="og:site_name" content={seo.siteName} />}
      {
        <meta
          property="og:type"
          content={isBlogPost ? "article" : "business.business"}
        />
      }
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.image && <meta property="og:image" content={seo.image} />}
      <meta name="twitter:card" content="summary_large_image" />
      {/* Twitter */}
      {twitterUsername && (
        <meta name="twitter:creator" content={twitterUsername} />
      )}
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      {seo.image && <meta name="twitter:image" content={seo.image} />}

      {/* Article */}
      {isBlogPost && (
        <meta property="article:published_time" content={seo.date} />
      )}
      {isBlogPost && <meta property="article:author" content={seo.author} />}
    </Helmet>
  );
};

export default SEO;

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
};

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
};

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        twitterUsername
      }
    }
  }
`;
