import classNames from "classnames";
import { Link as GatsbyLink } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import React, { useEffect, useState } from "react";
import logo from "../img/logo.svg";

const menuItems = [
  { name: "About us", href: "/about-us" },
  { name: "Blog", href: "/blog" },
  {
    name: "Download",
    href: "https://licensing.leeds.ac.uk/product/compunetics-software",
    button: true,
  },
];

const Link = ({ children, to, ...rest }) => {
  const isExternal = to.match(/https?:\/\//);
  return isExternal ? (
    <OutboundLink href={to} {...rest}>
      {children}
    </OutboundLink>
  ) : (
    <GatsbyLink to={to} {...rest}>
      {children}
    </GatsbyLink>
  );
};

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  function handleResize() {
    if (window.innerWidth > 991) {
      setMenuOpen(false);
    }
  }
  useEffect(() => {
    window.addEventListener("resize", handleResize);
  });

  return (
    <div className="nav">
      <div
        className="navbar w-nav"
        data-animation="default"
        data-collapse="medium"
        data-duration="400"
      >
        <div className="container-2 w-container">
          <Link className="w-nav-brand" to="/">
            <img alt="Compunetics" className="image-2" src={logo} width="250" />
          </Link>

          <nav className="w-nav-menu" role="navigation">
            {menuItems.map(({ name, href, button }) => (
              <Link
                onClick={() => {
                  setMenuOpen(false);
                }}
                key={name}
                className={classNames("link", "w-nav-link", {
                  linkbtn: button,
                })}
                to={href}
              >
                {name}
              </Link>
            ))}
          </nav>

          <div
            className={classNames("w-nav-button", { "w--open": menuOpen })}
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <div className="w-icon-nav-menu"></div>
          </div>
        </div>

        {/* TODO: Generate this from a consistent set of links */}
        <div
          className="w-nav-overlay"
          style={{ display: menuOpen ? "block" : undefined, height: "100vh" }}
        >
          <nav
            className="w-nav-menu w--nav-menu-open"
            role="navigation"
            style={{
              transform: "translateY(0px) translateX(0px)",
              transition: "transform 400ms ease 0s",
            }}
          >
            {menuItems.map(({ name, href, button }) => (
              <Link
                onClick={() => {
                  setMenuOpen(false);
                }}
                key={name}
                className={classNames(
                  "link",
                  "w-nav-link",
                  "w--nav-link-open",
                  {
                    linkbtn: button,
                  }
                )}
                style={{ maxWidth: "728px" }}
                to={href}
              >
                {name}
              </Link>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
