import { css, Global } from "@emotion/core";
import styled from "@emotion/styled";
import { Link as GatsbyLink, withPrefix } from "gatsby";
import "gatsby-remark-mathjax-ssr/mathjax.css";
import React from "react";
import CookieConsent from "react-cookie-consent";
import { Helmet } from "react-helmet";
import { Link, ThemeProvider } from "theme-ui";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import "./main.css";
import { reset } from "./reset";
import SEO from "./SEO";

const stickyTable = css`
  .remark-sticky-table-table {
    max-width: calc(100vw - 2rem);
  }
`;

const navbarHeight = "102px";

const ContentContainer = styled.div`
  padding-top: ${navbarHeight};
  /* Hacky fix for blog page - looks strange with single post */
  min-height: calc(100vh - ${navbarHeight});
`;

const theme = {
  fonts: {
    heading: "Rubik, sans-serif",
    body: "Karla, sans-serif",
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 96],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  colors: {
    text: "#000",
    background: "#fff",
    primary: "#eec900",
  },
  styles: {
    root: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
    },
  },
  radii: {
    none: "0",
    sm: "2px",
  },
  forms: {
    input: {
      backgroundColor: "background",
      borderRadius: "none",
    },
  },
  text: {
    secondary: {
      color: "background",
    },
  },
  buttons: {
    primary: {
      color: "primary",
      borderColor: "primary",
      borderWidth: "2px",
      borderStyle: "solid",
      backgroundColor: "text",
      borderRadius: "none",
      paddingY: 3,
      paddingX: 4,
      fontWeight: "bold",
    },
  },
  links: {
    primary: { color: "secondary" },
    secondary: { color: "primary" },
  },
};

const Layout = ({ children, title }) => {
  return (
    <div>
      <Global styles={[reset, stickyTable]} />
      <Helmet>
        <html lang="en" />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix("/")}apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={`${withPrefix("/")}favicon-32x32.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={`${withPrefix("/")}favicon-16x16.png`}
        />
        <link rel="manifest" href={`${withPrefix("/")}site.webmanifest`} />
        <meta name="msapplication-config" content="/browserconfig.xml" />
        <meta name="msapplication-TileColor" content="000000"></meta>
        <meta name="theme-color" content="000000" />
      </Helmet>

      <SEO />

      <ThemeProvider theme={theme}>
        <Navbar />

        <ContentContainer>{children}</ContentContainer>

        <Footer />

        <CookieConsent
          location="bottom"
          buttonText="Accept"
          declineButtonText="Decline"
          onAccept={() => window.location.reload()}
          cookieName="gatsby-gdpr-google-analytics"
        >
          Compunetics uses cookies to improve your experience. By continuing to
          use this website, you consent to the use of cookies in accordance with
          our{" "}
          <Link variant="secondary" as={GatsbyLink} to="privacy-policy">
            privacy policy
          </Link>
        </CookieConsent>
      </ThemeProvider>
    </div>
  );
};

export default Layout;
